<template>
  <div class="help-error">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'bf-help-error',
  data: () => ({})
};
</script>
