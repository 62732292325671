<template>
  <div class="help-text">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'bf-help-text',
  data: () => ({})
};
</script>
